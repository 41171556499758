import { createApp } from 'vue'
import { createPinia } from "pinia";
import VueCookies from 'vue-cookies';
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
createApp(App)
    .use(router)
    .use(createPinia())
    .use(VueCookies, {
        expires: '1d'
    })
    .mount('#app')

import { SummaryItem } from '@/models/SummaryItem';
import { TaskDto as Task } from '@/models/TaskDto';
import AuthService from './AuthService';
import router from '@/router';

class DataService {
    private host = process.env.VUE_APP_APIURL;//"127.0.0.1"; //"3.214.164.243";
    private port = process.env.VUE_APP_APIPORT; //"5000";
    private appRoot = process.env.VUE_APP_APIROOT ? '/' + process.env.VUE_APP_APIROOT : ''; //"" , "/timetracking-dev"

    private url = `${process.env.VUE_APP_APIPROTOCOL}://${this.host}:${this.port}${this.appRoot}`;
    private auth: AuthService;
    private userId: string;
    private token: string;
    public static DTO = {
        actions: "actions",
        clients: "clients",
        persons: "persons",
        sectors: "sectors",
        commands: "commands"
    }

    constructor() {
        this.auth = new AuthService()
        if (!this.auth.isAuthenticated()) {
            //si el usuario no está autenticado lo remito al login
            router.push({ name: "login" });
        }
        this.userId = this.auth.getValue('userId')
        this.token = this.auth.getValue('token')
    }


    private getOpts(method = 'GET', data = ""): RequestInit {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json')
        myHeaders.append("Authorization", "Bearer " + this.token);

        let opts: any;

        

        if (method == "GET") {//methos GET cannot have a body!!
            opts = {
                method: method, // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: myHeaders,
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            }
        } else {
            opts = {
                method: method, // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: myHeaders,
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: data,
            };
        }
        return opts;
    }

    ///api/users/{user_id}/tasks/active
    public getActiveTask(callback: any): void {
        const url = `${this.url}/api/users/${this.userId}/tasks/active`;
        try {
            fetch(url, this.getOpts())
                .then(res => {
                    if (!res.ok) {
                        throw new Error(`DataService::getActiveTask::HTTP error ${res.status}`);
                    }
                    return res.json();
                })
                .then(json => {
                    console.log(json)
                    callback(json)
                })
                .catch((e) => {
                    console.log(e)
                })
        } catch (e) {
            console.log("No Open task found in the DB")
        }
    }

    public getClient(id: number, callback: any) {
        if (!id)
            console.log('getClientDescription:: id cliente indefinido')
        else {
            const url = `${this.url}/api/clients/${id}`;
            this.getDataWithCallback(url, callback);
        }

    }

    public getAction(id: number, callback: any) {
        if (!id)
            console.log('getAction:: id acción indefinida')
        else {
            const url = `${this.url}/api/actions/${id}`;
            this.getDataWithCallback(url, callback);
        }

    }

    public getUserData(callback: any) {
        const url = `${this.url}/api/users/${this.userId}`;
        this.getDataWithCallback(url, callback);
    }

    public getDataWithCallback(url: string, callback: any) {
        console.log("calling::" + url)

        fetch(url, this.getOpts())
            .then(res => res.json())
            .then(json => {
                console.log(json)
                callback(json)
            })
    }

    public async getDataAwait(url: string): Promise<any> {
        const res = await fetch(url)
        const json = await res.json();
        return json;
    }

    //not filtered by user!!!
    public getDataItemsAll(itemType: string, callback: any) {
        const url = `${this.url}/api/${itemType}`;
        this.getDataWithCallback(url, callback);
    }

    /*
    dto: 
    actions
    clients
    */
    public getDataItems(itemType: string, callback: any, parentType?: string, parentId?: number): void {
        //console.log("getDataItems::UserId=" + this.userId + "; Item Type = " + itemType + "; ParentType=" + parentType + "; parent ID=" + parentId);
        let url = `${this.url}/api`;
        if ((itemType == DataService.DTO.persons) || (itemType == DataService.DTO.sectors))
            url += `/${itemType}`;
        else if ((itemType == DataService.DTO.clients) && ((parentId ? parentId : -1) > 0)) {
            //CLIENTS With Parent!
            url += `/${parentType}/${parentId}/${itemType}`;
        }
        else
            url += `/users/${this.userId}/${itemType}`;

        this.getDataWithCallback(url, callback);
    }

    public fetchDaysSummary(numDays: number, callback: any): void {
        const url = `${this.url}/api/users/${this.userId}/tasks/days/${numDays}`
        this.getDataWithCallback(url, callback);
    }


    //receive daily summary of tasks
    public fetchSummary(dateSelected: string, callback: any): void {
        const url = `${this.url}/api/users/${this.userId}/actions/summary/${dateSelected}`

        this.getDataWithCallback(url, callback);
    }

    public sendTask(task: Task, command: string, callback: any): void {
        //si el task es un comando envío el comando correspondiente.
        let url = `${this.url}/api/tasks`;
        if (command == 'STOP') {
            url = url + "/stop";
        }

        task.id = task.id?task.id:-1;

        fetch(url, this.getOpts('POST', JSON.stringify(task)))
            .then((res) => {
                if (!res.ok)
                    console.log("DataService::sendTask::Error while sending task: ", JSON.stringify(task), res);
                return res.text()
            }).then((id_res) => {
                console.log("DataService::sendTask::RECEIVED:" + id_res)
                callback(id_res);
            })
    }

    public editTask(task: Task, callback: any): void {
        //si el task es un comando envío el comando correspondiente.
        const url = `${this.url}/api/tasks/edit`;

        fetch(url, this.getOpts('PUT', JSON.stringify(task)))
            .then((res) => {
                if (!res.ok)
                    console.log("DataService::editTask::Error while sending task: " + JSON.stringify(task));
                return res.text()
            }).then((id_res) => {
                callback(id_res);
            })
    }


    //from ActivitySummary
    public updateActivity(action: string, item: SummaryItem, callback: any): void {
        let activeMethod = ""; //default method
        switch (action) {
            case "new":
                activeMethod = "POST"
                break;
            case "delete":
                activeMethod = "DELETE"
                break;
            case "update":
                activeMethod = "PUT"
                break;
            default:
            //error!!
        }

        const jsonOut = JSON.stringify(item)
        console.log("DataService::sendTask::action=" + action + " \n " + jsonOut)

        const url = `${this.url}/api/tasks/${action}`;
        const options: any = {
            method: activeMethod, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json' // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: jsonOut // body data type must match "Content-Type" header
        }

        fetch(url, this.getOpts(activeMethod, jsonOut))
            .then((res) => {
                if (res.ok)
                    console.log("DataService::updateActivityItem::Item:" + jsonOut + " sent to Backend!");
                else
                    console.log("DataService::updateActivityItem::Error while sending item: ", jsonOut, res);
                return res.text()
            }).then((msg) => {
                callback(msg);
            })
    }

    public getDataChart(req: any, callback: any) {
        const url = `${this.url}/api/analytics/chartdata`;
        
        fetch(url, this.getOpts('POST', JSON.stringify(req)))
            .then(res => res.json())
            .then(data => {
                callback(data);
            })
    }
}




export default DataService
import DBService from "./DBService";

class ValidationService extends DBService{
    
    //get list of available reports
    public getDaysToValidate(userId:number, callback:any){
        const endpoint = '/api/users/' + userId + '/days-to-validate';
        this.getDataWithCallback(endpoint, callback);
    }

    public setDayValid(userId:number, day:string, callback:any){
        const endpoint = '/api/users/validate-day';
        const data = {userId: userId, vday: day};
        this.sendDataWithCallback(endpoint, data, callback);
    }

}

export default ValidationService;
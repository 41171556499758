import AuthService from "./AuthService";
import router from "@/router";

class DBService {
    private host = process.env.VUE_APP_APIURL;//"127.0.0.1"; //"3.214.164.243";
    private port = process.env.VUE_APP_APIPORT; //"5000";
    private appRoot = process.env.VUE_APP_APIROOT ? '/' + process.env.VUE_APP_APIROOT : ''; //"" , "/timetracking-dev"

    private url = `${process.env.VUE_APP_APIPROTOCOL}://${this.host}:${this.port}${this.appRoot}`;
  private auth: AuthService;
  private userId: string;
  private token: string;

  constructor() {
    this.auth = new AuthService();
    if (!this.auth.isAuthenticated()) {
      //si el usuario no está autenticado lo remito al login
      router.push({ name: "login" });
    }
    this.userId = this.auth.getValue("userId");
    this.token = this.auth.getValue("token");
  }

  private getOpts(method = "GET", data = ""): RequestInit {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + this.token);

    let opts: any;

    if (method == "GET") {
      //methos GET cannot have a body!!
      opts = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: myHeaders,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      };
    } else {
      opts = {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: myHeaders,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: data,
      };
    }
    return opts;
  }

  public getDataWithCallback(endpoint: string, callback: any) {
    console.log("get::" + this.url + endpoint);

    fetch(this.url + endpoint, this.getOpts())
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        callback(json);
      });
  }

  public sendDataWithCallback(endpoint: string, data: any, callback: any) {
    console.log(this.getOpts("POST", data));
    fetch(this.url + endpoint, this.getOpts("POST", JSON.stringify(data)))
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        callback(json);
      });
  }

  public async getDataAwait(endpoint: string): Promise<any> {
    const res = await fetch(this.url + endpoint, this.getOpts());
    const json = await res.json();
    return json;
  }

  public async sendDataAwait(endpoint: string, data: any): Promise<any> {
    const res = await fetch(this.url + endpoint, this.getOpts("POST", data));
    const json = await res.json();
    return json;
  }
}

export default DBService;

import { ItemType } from "./ItemType";

export class Item {
    id: number;
    cod: string;
    des: string;
    type: string;
    param: string;
    public constructor(id?:number, cod?:string, des?:string, type?:string, param?:string){
      this.id=id?id:-1;
      this.cod=cod?cod:"";
      this.des=des?des:"";
      this.type=type?type:"";
      this.param=param?param:"";
    }    
  }
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import SummaryView from '@/views/SummaryView.vue'
import LoginView from '@/views/LoginView.vue'
import DataAnalysis from '@/components/DataAnalysis.vue'
import ChartView from '@/views/ChartView.vue'
//import { useAuthStore } from '@/stores/auth.store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    //component: HomeView
    component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue')
  },
  {
    path: '/summary/:type',
    name: 'summary',
    component: SummaryView,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: DataAnalysis,
    props: true
  },
  {
    path: '/charts',
    name: 'charts',
    component: ChartView,
  }

]

const router = createRouter({
  history: createWebHistory(), //createWebHistory(process.env.BASE_URL)
  routes
});

/* router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.user) {
      auth.returnUrl = to.fullPath;
      return '/login';
  }
}); */

export default router

export class TaskDto {
    public id?:number;
    private userId?:number;
    public actionId?:number;
    public clientId?:number;
    public txt?:string;
    public txtAct?:string;
    public txtCli?:string;
    public timeStart?: Date;
    public timeEnd?: Date;
    public status?:string;
    getUserId(){
        return this.userId;
    }
    setUserId(id:number){
        this.userId = id;
    }
}
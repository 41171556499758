class AuthService {
  private urlInfix = process.env.VUE_APP_APIROOT?'/'+process.env.VUE_APP_APIROOT:'';
  private url = `${process.env.VUE_APP_APIPROTOCOL}://${process.env.VUE_APP_APIURL}:${process.env.VUE_APP_APIPORT}${this.urlInfix}/api/auth/login`
  //private user: any;
  private error: string;

  private storage = sessionStorage; //Aquí se puede elegir el tipo de "Storage"

  constructor() {
    this.error = "";
  }

  public isAuthenticated(): boolean {
    return (this.getValue('token') != "")
  }


  public getValue(v: string): string {
    const t = this.storage.getItem(v);
    return t || '';
  }

  private storeAuth(tkn: string, usrId: string, fullName:string, agreg: string): void {
    this.storage.setItem("token", tkn);
    this.storage.setItem("userId", usrId);
    this.storage.setItem("fullName", fullName);
    this.storage.setItem("agreg", agreg);

  }

  public getError(): string {
    return this.error;
  }

  public async login(username: string, password: string): Promise<boolean> {
    this.error = ""
    const loginData = {
      username: username,
      password: password
    }

    const opt: any = {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginData)
    }
    try {
      console.log(opt)
      const res = await fetch(this.url, opt);
      console.log(res)
      const json = await res.json();
      console.log(json)
      if ('errors' in json) {
        this.error = 'Login error';
        return false;
      }
      this.storeAuth(json.token, json.userId, json.name, json.agreg)
    } catch (err: any) {
      console.log(err);
      return false;
    }
    return true;
  }

  public logout(): void {
    this.storeAuth('', '', '', '');
    //redirect to home page or login ??
  }

}

export default AuthService;
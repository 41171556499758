import { Item } from "./Item"

export class SummaryItem {
    id: number;
    userId: number;
    action: Item;
    client: Item;
    actionDate: Date;
    timeStart: Date;
    timeEnd: Date;
    followingStart: Date;
    previousEnd: Date;
    duration: number;
    colorId: number;
    r: number;
    g: number;
    b: number;
    txt: string;
    txtAct: string;
    txtCli: string;
    status: number;
    isNew: boolean;
    constructor(){
      this.id = 0;
      this.userId = -1;
      this.action = new Item();
      this.client = new Item();
      this.actionDate = new Date('2023-01-01');
      this.timeStart = new Date();
      this.timeEnd = new Date();
      this.followingStart = new Date();
      this.previousEnd = new Date();
      this.duration=0;
      this.colorId=0;
      this.r=0;
      this.g=0;
      this.b=0;
      this.txt = "";
      this.txtAct = "";
      this.txtCli = "";
      this.status = 1;
      this.isNew = false;
    }

    public static getNew(id: number, userId: number):SummaryItem{
      //const hoy = new Date();
      //const strDate = `${hoy.getFullYear()}/${hoy.getMonth()}/${hoy.getDate()}` 


      //uso JSON para inicializar un nuevo elemento de Summary (buscar una solución mejor!!!)
      const json = {
        "id": id,
        "userId": userId,
        "action": {
            "id": -1,
            "cod": "",
            "des": ""
        },
        "client": {
            "id": -1,
            "cod": "",
            "des": ""
        },
        "actionDate": null,
        "timeStart": null,
        "timeEnd": null,
        "previousEnd": null,
        "followingStart": null,
        "duration": 0,
        "colorId": 0,
        "r": 0,
        "g": 0,
        "b": 0,
        "txt": "",
        "txtAct": "",
        "txtCli": "",
        "status": 1,
        "isNew": true
    }
      
      let i = new SummaryItem()
      i = JSON.parse(JSON.stringify(json));
      return i;
    }
  }   
import DBService from "./DBService";

class ReportService extends DBService{
    private endpoint = '/api/analytics/reports'

    //get list of available reports
    public getAll(callback:any){
        this.getDataWithCallback(this.endpoint, callback);
    }

    //recupero datos del informe segun los paramatros enviados
    // POST - /api/reports/{id_report}
    public getData(params:any, callback:any){
        this.sendDataWithCallback(this.endpoint, params, callback);
    }
}

export default ReportService;